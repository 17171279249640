<template>
    <div class="main-container">
        <div>
            <!-- Loop to generate up to 5 stars -->
            <template v-for="star in stars" :key="star">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19"
                    :class="star <= rating ? 'filled' : ''">
                    <path
                        d="M9.07088 0.612343C9.41462 -0.204115 10.5854 -0.204114 10.9291 0.612346L12.9579 5.43123C13.1029 5.77543 13.4306 6.01061 13.8067 6.0404L19.0727 6.45748C19.9649 6.52814 20.3267 7.62813 19.6469 8.2034L15.6348 11.5987C15.3482 11.8412 15.223 12.2218 15.3106 12.5843L16.5363 17.661C16.744 18.5211 15.7969 19.201 15.033 18.7401L10.5245 16.0196C10.2025 15.8252 9.7975 15.8252 9.47548 16.0196L4.96699 18.7401C4.20311 19.201 3.25596 18.5211 3.46363 17.661L4.68942 12.5843C4.77698 12.2218 4.65182 11.8412 4.36526 11.5987L0.353062 8.2034C-0.326718 7.62813 0.0350679 6.52814 0.927291 6.45748L6.19336 6.0404C6.5695 6.01061 6.89716 5.77543 7.04207 5.43123L9.07088 0.612343Z"
                        class="svg-color" />
                </svg>
            </template>
        </div>
        <div class="description">
            {{ testimonialText }}
        </div>
        <div class="testimonial">
            <img loading="lazy" :src="imageUrl1" :alt="testimonialName" class="testimonial-img" />
            <div class="testimonial-info">
                <div class="testimonial-name">{{ testimonialName }}</div>
                <div class="testimonial-role">{{ testimonialRole }}</div>
            </div>
            <img loading="lazy" :src="imageUrl2" class="testimonial-corp-img" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        testimonialText: {
            type: String,
            default: 'I absolutely love the digital art I purchased from 6static. The quality is outstanding and it adds a unique touch to my space.',
        },
        testimonialName: {
            type: String,
            default: 'John Doe',
        },
        testimonialRole: {
            type: String,
            default: 'CEO, XYZ Corp',
        },
        rating: {
            type: Number,
            default: 5,
        },
        imageUrl1: {
            type: String,
            default: require('@/assets/PlaceholderImage.png'),
        },
        imageUrl2: {
            type: String,
            default: require('@/assets/PlaceholderImage.png'),
        },
    },
    computed: {
        stars() {
            // Return an array [1, 2, 3, 4, 5] for star loop
            return Array.from({ length: 5 }, (_, i) => i + 1)
        },
    },
}
</script>

<style scoped>
.filled .svg-color {
    fill: var(--primary-color, #000);
}

.main-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
}

.description {
    color: var(--primary-color, #000);
    text-align: center;
    font: 700 1.5rem/140% var(--secondary-font-family), sans-serif;
    width: 48rem;
}

@media (max-width: 991px) {
    .description {
        max-width: 100%;
    }
}

.testimonial {
    align-items: flex-start;
    align-self: center;
    display: flex;
    max-width: 100%;
}

@media (max-width: 991px) {
    .testimonial {
        justify-content: center;
    }
}

.testimonial-img {
    object-fit: cover;
    object-position: center;
    width: 3.5rem;
    overflow: hidden;
    border-radius: 50%;
    align-self: stretch;
    max-width: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
}

.testimonial-corp-img {
    aspect-ratio: 2.5;
    object-fit: cover;
    object-position: center;
    width: 7.5rem;
    overflow: hidden;
    align-self: center;
    max-width: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
}

.testimonial-info {
    align-items: flex-start;
    align-self: center;
    display: flex;
    flex-direction: column;
}

.testimonial-name {
    color: var(--primary-color, #000);
    align-self: flex-start;
    font: 600 1rem/150% var(--secondary-font-family), sans-serif;
}

.testimonial-role {
    color: var(--primary-color, #000);
    font: 400 1rem/150% var(--secondary-font-family), sans-serif;
}

.testimonial-img:last-child {
    aspect-ratio: 2.5;
    object-fit: cover;
    object-position: center;
    width: 120px;
    overflow: hidden;
    align-self: center;
    max-width: 100%;
}
</style>