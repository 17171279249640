<template>
    <section class="main-container">
        <article v-for="container in containers" :key="container.title" class="section-container">
            <div class="image-wrapper">
                <img loading="lazy" :src="imageUrl" class="img" :alt="imageAlt" />
                <h2>{{ container.title }}</h2>
                <p>{{ container.description }}</p>
            </div>
            <div class="button-container">
                <LearnMoreButton :label="'Learn More'" :targetUrl="container.learnMoreUrl" />
                <SignUpButton :label="'Sign Up'" :targetUrl="container.signUpUrl" />
            </div>
        </article>
    </section>
</template>

<script>
import SignUpButton from '@/components/SignUpButton.vue';
import LearnMoreButton from '@/components/LearnMoreButton.vue';

export default {
    components: {
        SignUpButton,
        LearnMoreButton,
    },
    props: {
        imageUrl: {
            type: String,
            default: require('@/assets/images/layout-1-icon-chevron-right.svg')
        },
        imageAlt: {
            type: String,
            default: "Image"
        },
    },
    data() {
        return {
            defaultContainer: {
                title: 'Discover the World of Digital Art and Unleash Your Creativity',
                description: 'Our digital art products offer a range of customization options, high-resolution files, and creative designs.',
                signUpUrl: '#signup',
                learnMoreUrl: '#learnmore',
            },
            customContainers: [
                // ... can add or remove custom containers here
            ],
        }
    },
    computed: {
        containers() {
            const containers = [...this.customContainers]
            while (containers.length < 3) {
                containers.push(this.defaultContainer)
            }
            return containers
        }
    }
}
</script>

<style scoped>
.main-container {
    display: flex;
    justify-content: space-between;
}

.section-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 33%;
}

.image-wrapper {
    align-self: center;
    width: 100%;
    max-width: 1312px;
}

.button-container {
    display: flex;
    gap: 1rem;
    margin-top: 40px;
}

@media (max-width: 991px) {
    .image-wrapper {
        max-width: 100%;
        margin: 1.5rem 0;
    }

    .section-container {
        width: 100%;
    }

    .button-container {
        margin-top: 0;
    }
}

</style>