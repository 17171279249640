<template>
    <PortfolioItemHeaderComponent
        :title="'Portfolio Item Title'"
        :date="'Month Year'"
        :description="'Portfolio Item Description'" />
    <PortfolioItemBodyComponent :title="'Opportunity'" />
    <PortfolioItemBodyComponent :title="'Process'" />
    <PortfolioItemBodyComponent :title="'Outcome'" />
    <!-- Gallery Section - Showcase Images of the Portfolio Item -->
    <!-- Testimonial -->
    <!-- Portfolio List Section - Showcase a list of other Porfolio Items -->
</template>
<script>
import PortfolioItemHeaderComponent from '@/components/builder/PortfolioItemHeaderComponent.vue'
import PortfolioItemBodyComponent from '@/components/builder/PortfolioItemBodyComponent.vue'

export default {
    name: 'PortfolioPage',
    components: {
        PortfolioItemHeaderComponent,
        PortfolioItemBodyComponent
    }
}
</script>
