<template>
    <section class="section-container">
        <header :class="[{ isLeft: isLeftBound }, 'header']">
            <h1 class="sub">{{ subheading }}</h1>
        </header>
        <h2 class="section-title">{{ title }}</h2>
        <p class="section-description">{{ description }}</p>
    </section>
</template>

<script>
export default {
    name: 'SectionTitleComponent',
    props: {
        subheading: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            default:
                'This is a section description. It can be used to describe the section and its purpose.'
        },
        isLeftBound: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
.header {
    text-align: center;
}

.sub {
    color: var(--primary-color, #000);
    font: 600 1rem/150% var(--secondary-font-family, sans-serif);
}

.section-container {
    align-items: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 768px;
    max-width: 100%;
}

@media (max-width: 991px) {
    .section-container {
        margin-top: 40px;
        flex-direction: column;
        display: flex;
    }
}

.section-title {
    color: var(--primary-color, #000);
    text-align: center;
    font: 700 3rem/120% var(--secondary-font-family, sans-serif);
}

@media (max-width: 991px) {
    .section-title {
        max-width: 100%;
    }
}

.section-description {
    color: var(--primary-color, #000);
    text-align: center;
    margin-top: 2rem;
    font: 400 1.125rem/150% var(--secondary-font-family, sans-serif);
}

@media (max-width: 991px) {
    .section-description {
        max-width: 100%;
    }
}

:has(.isLeft) {
    align-items: flex-start;
    justify-content: flex-start;
    align-self: flex-start;
}
</style>
