<template>
    <section class="main-container is-column">
        <div class="title-column">
            <h1 class="title">{{ title }}</h1>
        </div>
        <div class="column description-column">
            <div class="description">
                <p class="description-text">{{ description }}</p>
                <div v-if="showButtons" class="button-wrapper">
                    <ButtonComponent
                        :isPrimary="true"
                        label="Browse"
                        :to="linkPrimary"
                    />
                    <ButtonComponent
                        :isPrimary="false"
                        label="Get Started"
                        :to="linkSecondary"
                    />
                </div>
            </div>
        </div>
        <div v-if="showImage" class="image-wrapper">
            <img loading="lazy" :src="imageSrc" :alt="imageAlt" />
        </div>
    </section>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent.vue'

export default {
    components: {
        ButtonComponent
    },
    props: {
        title: {
            type: String,
            default: 'Discover the World of Digital Art'
        },
        description: {
            type: String,
            default:
                'Explore our curated collection of stunning digital art and unleash your creativity.'
        },
        showImage: {
            type: Boolean,
            default: true
        },
        imageSrc: {
            type: String,
            default: require('@/assets/PlaceholderImage.png')
        },
        imageAlt: {
            type: String,
            default: 'Placeholder Image'
        },
        showButtons: {
            type: Boolean,
            default: true
        },
        linkPrimary: {
            type: String,
            default: '/shop'
        },
        linkSecondary: {
            type: String,
            default: '/bot-services'
        }
    },
    methods: {
        handleBrowseClick() {
            // Handle browse button click event
            window.location.href = this.linkPrimary
        },
        handleGetStartedClick() {
            // Handle get started button click event
            window.location.href = this.linkSecondary
        }
    }
}
</script>

<style scoped>
.main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.image-wrapper {
    width: 100%;
    display: flex;
    align-self: stretch;
    height: 500px;
}

img {
    width: 100%; /* Stretch the image horizontally */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Or use 'contain' depending on your needs */
    display: block; /* Remove default spacing under the image */
}

.image-wrapper img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.content-wrapper {
    display: flex;
    width: 100%;
    gap: 5rem;
    padding-top: 0;
    flex-direction: column;
    align-items: center;
}

.column {
    width: 80%;
}

.title {
    color: var(--primary-color);
    font-size: 3.5rem;
    font-weight: bold;
}

.description-text {
    color: var(--primary-color);
    font-size: 1.125rem;
}

.button-wrapper {
    margin-top: 20px;
    display: flex;
    gap: 16px;
}

@media (max-width: 991px) {
    .content-wrapper {
        flex-direction: column;
    }

    .column {
        width: 100%;
    }

    .button-wrapper {
        flex-direction: row;
    }
}
</style>
