<template>
    <section class="portfolio-item-header">
        <div class="portfolio-item-header__container">
            <div class="portfolio-item-header__content">
                <h1 class="portfolio-item-header__title">{{ title }}</h1>
                <p class="portfolio-item-header__date">{{ date }}</p>
                <p class="portfolio-item-header__description">{{ description }}</p>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'PortfolioItemHeaderComponent',
    props: {
        title: {
            type: String,
            default: 'Portfolio Item Title'
        },
        date: {
            type: String,
            default: 'Month Year'
        },
        description: {
            type: String,
            default: 'Portfolio Item Description'
        }
    }
}
</script>