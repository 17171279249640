<template>
    <ErrorPage
        title="404 - Page Not Found"
        message="Sorry, the page you are looking for does not exist or still is in development."
    />
</template>
<script>
import ErrorPage from '@/views/ErrorPage.vue'
export default {
    name: 'NotFoundPage',
    components: {
        ErrorPage
    }
}
</script>
