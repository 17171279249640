<template>
    <ShopPage />
</template>
<script>
import ShopPage from './ShopPage.vue'
export default {
    name: 'DigitalArtPage',
    components: {
        ShopPage
    }
}
</script>