<template>
    <hr class="accordion-item" />
    <div v-for="(item, index) in items" :key="index" class="accordion-item">
        <div class="accordion-header" @click="toggle(index)">
            <h3>{{ item.question }}</h3>
            <span v-if="activeIndex === index">-</span>
            <span v-else>+</span>
        </div>
        <div class="accordion-content" v-if="activeIndex === index">
            <p v-html="item.answer"></p>
        </div>
        <hr />
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            activeIndex: null
        }
    },
    methods: {
        toggle(index) {
            this.activeIndex = this.activeIndex === index ? null : index
        }
    }
}
</script>

<style scoped>
@import '@/styles/sharedButton.css';

.accordion-item {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.accordion-header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 1rem 0;
}

.accordion-content {
    padding: 1rem 0;
}
</style>
