<template>
    <div class="main-container">
        <div class="header">
            <h1 class="title">{{ title }}</h1>
        </div>
        <div class="content">
            <div class="description">
                <p>
                    {{ description }}
                </p>
            </div>
            <div class="content-wrapper">
                <form>
                    <input type="email" placeholder="Enter your email address" class="secondary-button" />
                </form>
                <SignUpButton type="submit" @click="handleButtonClick" />
            </div>
            <div class="terms">
                <p>
                    By clicking Sign Up you're confirming that you agree with our <a href="#terms">Terms
                    and Conditions</a>.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import SignUpButton from '@/components/SignUpButton.vue'

export default {
    name: 'CtaWithSignUpComponent',
    components: {
        SignUpButton,
    },
    methods: {
        handleButtonClick() {
            // Handle button click event
        },
    },
    props: {
        title: {
            type: String,
            default: 'Medium length heading goes here',
        },
        description: {
            type: String,
            default: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                    varius enim in eros elementum tristique. Duis cursus, mi quis viverra
                    ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.`
        }
    },
}
</script>

<style scoped>
.header {
    align-self: flex-start;
    width: 100%;
    max-width: 1312px;
}

@media (max-width: 991px) {
    .header {
        max-width: 100%;
        margin: 40px 0;
    }
}

.content {
    gap: 20px;
    display: flex;
    flex-direction: column;
}

@media (max-width: 991px) {
    .content {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
}

.title {
    color: var(--primary-color, #000);
    flex: 1;
    font: 700 3rem/120% var(--secondary-font-family), sans-serif;
}

@media (max-width: 991px) {
    .title {
        max-width: 100%;
        margin-top: 40px;
    }
}

.description {
    color: var(--primary-color, #000);
    font: 400 1.125rem/150% var(--secondary-font-family), sans-serif;
}

@media (max-width: 991px) {
    .description {
        max-width: 100%;
    }
}

.content-wrapper {
    align-items: flex-start;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    gap: 20px;
}

@media (max-width: 991px) {
    .content-wrapper {
        max-width: 100%;
        margin-top: 40px;
    }
}

input {
    height: 50px;
}

img {
    width: 100%;
    max-width: 100%;
}

.terms {
    color: var(--primary-color, #000);
    /* text-decoration-line: underline;'' */
    margin-top: 16px;
    font: 400 0.75rem/150% var(--secondary-font-family), sans-serif;
}

@media (max-width: 991px) {
    .terms {
        max-width: 100%;
    }
}
</style>