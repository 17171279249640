<template>
    <BotServicesPage />
    <FeatureShowcaseComponent
        :features="digital_art.features"
        :sectionTitle="digital_art.sectionTitle"
        :sectionDescription="digital_art.sectionDescription"
        :showButtons="false"
    />
    <DiscoverComponent
        :title="discover.title"
        :description="discover.description"
        :imageSrc="discover.imageSrc"
        :imageAlt="discover.imageAlt"
    />
    <CtaComponent
        :title="cta.title"
        :description="cta.description"
        :imageSrc="cta.imageSrc"
        :imageAlt="cta.imageAlt"
        :button1="cta.button1"
        :button2="cta.button2"
    />
</template>

<script>
import DiscoverComponent from '@/components/builder/DiscoverComponent.vue'
import CtaComponent from '@/components/builder/CtaComponent.vue'
import FeatureShowcaseComponent from '@/components/builder/FeatureShowcaseComponent.vue'
import BotServicesPage from './BotServicesPage.vue'

export default {
    name: 'HomePage',
    components: {
        DiscoverComponent,
        CtaComponent,
        FeatureShowcaseComponent,
        BotServicesPage
    },
    data() {
        return {
            discover: {
                title: 'Discover the World of Digital Art',
                description: `6static is a digital art creator. Discover our products on our <a href="https://6static.redbubble.com">Redbubble Store</a>.`,
                imageSrc: require('@/assets/images/m6static_Preview_Tiles_001.jpg'),
                imageAlt: 'm6static Preview Tiles 001'
            },
            cta: {
                title: 'New Digital Art Products',
                description:
                    'Check out <a href="https://www.redbubble.com/i/mauspad/164845057.GAP22">Mousepad XXL - Nautikin Evolved 100</a>.',
                imageSrc: require('@/assets/images/promote/Mousepad XXL - Nautikin Entwickelt 100 vertikal.png'),
                imageAlt: 'Mousepad XXL - Nautikin Entwickelt 100 vertikal',
                button1: { label: 'Shop Now', to: 'https://www.redbubble.com/i/mauspad/164845057.GAP22' },
            },
            digital_art: {
                sectionTitle: 'Digital Art',
                sectionDescription:
                    'Explore our curated collection of digital art products, handpicked for their creativity and uniqueness. Each piece is a masterpiece, ready to enhance your digital space.',
                features: [
                    {
                        title: 'New Arrivals',
                        description:
                            'Check out the latest additions to our digital art collection.          '
                    },
                    {
                        title: 'Limited Editions',
                        description:
                            'Discover exclusive digital art pieces, available in limited quantities.'
                    },
                    {
                        title: 'Most Popular',
                        description:
                            'Browse our most popular digital art products, as chosen by our community.'
                    }
                ]
            }
        }
    }
}
</script>
