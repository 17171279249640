<template>
    <component
        :is="wrapperTag"
        v-bind="wrapperProps"
        class="button-wrapper"
        @click="$emit('click')"
    >
        <button :class="buttonClass">
            <slot>{{ label }}</slot>
            <component
                v-if="iconComponent"
                :is="iconComponent"
                :color="iconColor"
                class="button-icon"
            />
            <img
                v-if="imageSrc"
                :src="imageSrc"
                :alt="imageAlt"
                class="button-icon"
            />
        </button>
    </component>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: 'Button'
        },
        isPrimary: {
            type: Boolean,
            default: true
        },
        imageSrc: {
            type: String,
            default: null
        },
        imageAlt: {
            type: String,
            default: 'Button Icon'
        },
        borderVisible: {
            type: Boolean,
            default: true
        },
        iconComponent: {
            type: Object,
            default: null
        },
        iconColor: {
            type: String,
            default: 'var(--primary-color)'
        },
        to: {
            type: String,
            default: null
        }
    },
    computed: {
        isExternalLink() {
            // Detect if the link is external
            return (
                this.to &&
                (this.to.startsWith('http') ||
                    this.to.startsWith('mailto') ||
                    this.to.startsWith('//'))
            )
        },
        wrapperTag() {
            if (this.isExternalLink) {
                return 'a'
            }
            return this.to ? 'router-link' : 'div'
        },
        buttonClass() {
            return {
                'primary-button': this.isPrimary,
                'secondary-button': !this.isPrimary,
                'border-hidden': !this.borderVisible
            }
        },
        wrapperProps() {
            if (this.isExternalLink) {
                return {
                    href: this.to,
                    target: '_blank',
                    rel: 'noopener noreferrer' // For Security
                }
            }
            if (this.to) {
                return {
                    to: this.to
                }
            }
            return {}
        }
    }
}
</script>

<style scoped>
@import '@/styles/sharedButton.css';

.button-icon {
    margin-left: 8px;
    width: 16px;
    height: 16px;
}
</style>
