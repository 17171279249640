<template>
    <section v-if="showBareTemplate" class="main-container">
        <h1>Impressum</h1>

        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>Martin Pr&auml;tzlich<br />
            6static<br />
            c/o Block Services<br />
            Stuttgarter Str. 106<br />
            70736 Fellbach</p>

        <h2>Kontakt</h2>
        <p>Telefon: +49 (0) 123 44 55 66<br />
            Telefax: +49 (0) 123 44 55 99<br />
            E-Mail: support@6static.com</p>

        <h2>Umsatzsteuer-ID</h2>
        <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
            DE999999999</p>

        <h2>Redaktionell verantwortlich</h2>
        <p>Martin Pr&auml;tzlich<br />
            c/o Block Services<br />
            Stuttgarter Str. 106<br />
            70736 Fellbach</p>

        <h2>EU-Streitschlichtung</h2>
        <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a
                href="https://ec.europa.eu/consumers/odr/" target="_blank"
                rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden
            Sie
            oben im Impressum.</p>

        <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.</p>

        <p>Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a></p>
    </section>
    <section class="main-container">
        <div class="description">
            <h1>Impressum</h1>
            <p>Angaben gem&auml;&szlig; &sect; 5 TMG</p>

            <section class="address-info">
                <div class="item">
                    <div class="label">Company Name:</div>
                    <div class="value">{{ address.companyName }}</div>
                </div>
                <div class="item">
                    <div class="label">Name:</div>
                    <div class="value">{{ address.name }}</div>
                </div>
                <div class="item">
                    <div class="label">Address:</div>
                    <div class="value">{{ address.serviceprovider }}</div>
                </div>
                <div class="item">
                    <div class="label">Street:</div>
                    <div class="value">{{ address.street }}</div>
                </div>
                <div class="item">
                    <div class="label">Postal Code:</div>
                    <div class="value">{{ address.postalCode }}</div>
                </div>
                <div class="item">
                    <div class="label">City:</div>
                    <div class="value">{{ address.city }}</div>
                </div>
                <div class="item">
                    <div class="label">Country:</div>
                    <div class="value">{{ address.country }}</div>
                </div>
            </section>
            <section>
                <div v-if="contact.phone" class="item">
                    <div class="label">Phone:</div>
                    <div class="value">{{ contact.phone }}</div>
                </div>
                <div v-if="contact.fax" class="item">
                    <div class="label">Fax:</div>
                    <div class="value">{{ contact.fax }}</div>
                </div>
                <div class="item">
                    <div class="label">Email:</div>
                    <div class="value"><a :href="'mailto:' + contact.email">{{ contact.email }}</a></div>
                </div>
            </section>
            <section>
                <div v-if="finacial.taxId" class="item">
                    <div class="label">Tax ID:</div>
                    <div class="value">{{ finacial.taxId }}</div>
                </div>
                <div v-else-if="finacial.economicId" class="item">
                    <div class="label">Economic ID:</div>
                    <div class="value">{{ finacial.economicId }}</div>
                </div>
                <div v-else class="item">
                    <div class="label">Tax/Economic:</div>
                    <div class="value">not registered, yet</div>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ImprintComponent',
    props: {
        showBareTemplate: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            address: {
                companyName: '6static',
                name: 'Martin Prätzlich',
                serviceprovider: 'c/o Block Services',
                street: 'Stuttgarter Str. 106',
                postalCode: '70736',
                city: 'Fellbach',
                country: 'Germany',
            },
            contact: {
                phone: '+49 (0) 751 95888775',
                fax: null,
                email: 'service@6static.com',
            },
            finacial: {
                taxId: null,
                economicId: null,
            },
        }
    }
}
</script>

<style scoped>
section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font: 400 1.5rem/140% var(--secondary-font-family), sans-serif;
    width: 100%;
}

.description {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 40%;
    padding: 1rem;
}

@media (max-width: 991px) {
    .description {
        max-width: 100%;
    }
}

.address-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 0 auto;
}

.item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.5rem;
}

.label {
    font-weight: bold;
}

.value {
    text-align: right;
}

@media (max-width: 767px) {
    .description {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .address-info {
        width: 100%;
    }

    .description {
        width: 60%;
    }
}
</style>
