<template>
    <section class="main-container">
        <SectionTitleComponent
            subheading="About"
            title="Welcome at 6static!"
            :description="aboutUs.description"
            :isLeftBound="false"
        />
        <TeamComponent :isHirering="false" />
        <FaqComponent />
    </section>
</template>

<script>
import SectionTitleComponent from '@/components/SectionTitleComponent.vue'
import FaqComponent from '@/components/builder/FaqComponent.vue'
import TeamComponent from '@/components/builder/TeamComponent.vue'

export default {
    name: 'AboutUsPage',
    components: {
        SectionTitleComponent,
        FaqComponent,
        TeamComponent
    },
    data() {
        return {
            aboutUs: {
                description: `I am m6 (Martin), the creative mind behind 6static. I create unique, AI-generated artworks and offer digital products ranging from the abstract to futuristic designs.`
            }
        }
    }
}
</script>

<style scoped>
.main-container {
    flex-direction: column;
    /* align-items: center; */
}
</style>
