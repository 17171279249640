<template>
    <NavigationComponent />
    <router-view />
    <TrustLogo v-if="showTrustLogo" />
    <FooterComponent />
</template>

<script>
import NavigationComponent from '@/components/builder/NavigationComponent.vue'
import TrustLogo from '@/components/TrustLogo.vue'
import FooterComponent from '@/components/builder/FooterComponent.vue'

export default {
    name: 'App',
    methods: {
        isRoute(route) {
            return this.$route.path === route
        }
    },
    data() {
        return {
            showTrustLogo: false,
        }
    },
    components: {
        NavigationComponent,
        TrustLogo,
        FooterComponent
    },
}
</script>

<style>
#app {
    font-family: var(--primary-font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: var(--primary-color);
    background-color: var(--background-color);
}
</style>
