<template>
    <TermsComponent />
</template>
<script>
import TermsComponent from '@/components/builder/TermsComponent.vue'

export default {
    name: 'TermsPage',
    components: {
        TermsComponent,
    },
}
</script>