<template>
    <section class="discover-container" :class="{ 'is-centered' : isCentered }">
        <header class="discover-header">
            <h1 class="discover-title">{{ title }}</h1>
        </header>
        <h2 class="discover-subtitle">{{ subtitle }}</h2>
        <p class="discover-text">{{ text }}</p>
    </section>
</template>

<script>
export default {
    name: 'DiscoverComponent',
    props: {
        title: {
            type: String,
            default: 'Latest'
        },
        subtitle: {
            type: String,
            default: 'Discover the Art'
        },
        text: {
            type: String,
            default: 'Stay informed with our engaging blog posts'
        },
        isCentered: {
            type: Boolean,
            default: false
        },
    },
}
</script>

<style scoped>
.discover-container {
    display: flex;
    flex-direction: column;
}

.discover-container.is-centered {
    align-items: center;
}

.discover-header {
    display: flex;
    flex-direction: column;
}

.discover-title {
    color: var(--primary-color, #000);
    font: 600 1rem/150% var(--secondary-font-family, sans-serif);
}

.discover-subtitle {
    color: var(--primary-color, #000);
    font: 700 3rem/120% var(--secondary-font-family, sans-serif);
    margin-top: 1rem;
}

.discover-text {
    margin-top: 0.5rem;
}

@media (max-width: 991px) {

    .discover-container,
    .discover-header {
        max-width: 100%;
    }
}
</style>
