<template>
    <section class="main-container">
        <div v-if="window.location.protocol === 'https:'" id="trustLogoContainer" ref="trustLogoContainer">
            <h2>TrustLogo</h2>
            <img src="@/assets/images/positivessl_trust_seal_lg_222x54.png" alt="Positive SSL Trust Seal" />
        </div>
        <div v-else>
            <h2>TrustLogo</h2>
            <p>TrustLogo is only available on HTTPS sites.</p>
        </div>
    </section>
</template>
  
<script>
export default {
    mounted() {
        const tlJsHost = window.location.protocol === "https:" ? "https://secure.trust-provider.com/" : "http://www.trustlogo.com/"

        const trustLogoScript = document.createElement("script")
        trustLogoScript.src = `${tlJsHost}trustlogo/javascript/trustlogo.js`
        trustLogoScript.type = "text/javascript"

        document.head.appendChild(trustLogoScript)

        // Define TrustLogo parameters
        const trustLogoParams = {
            image: "https://www.positivessl.com/images/seals/positivessl_trust_seal_lg_222x54.png",
            service: "POSDV",
            style: "none"
        }

        // Initialize TrustLogo
        this.$nextTick(() => {
            window.TrustLogo(trustLogoParams, this.$refs.trustLogoContainer)
        })
    }
}
</script>
  
<style>
/* Add any necessary styles for your component here */
</style>
  