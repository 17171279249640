<template>
    <section class="main-container is-column">
        <SectionTitleComponent
            :title="title"
            :description="message"
        />
        <!-- Feature Section - Briefly explain the error and possible reasons it occured -->
        <!-- CTA Section - Provide a call-to-action for users to return to the Home Page or contact support for further assistance -->
        <!-- Contact Section - Offer contact information for immediate assistance -->
        <!-- FAQ Section - Include frequently asked questions related to common errors and troubleshooting steps -->
    </section>
</template>
<script>
import SectionTitleComponent from '@/components/SectionTitleComponent.vue'
export default {
    name: 'ErrorPage',
    components: {
        SectionTitleComponent
    },
    props: {
        title: {
            type: String,
            default: 'Error'
        },
        message: {
            type: String,
            default: 'An unexpected error has occurred. Please try again later.'
        },
        description: {
            type: String,
            default: 'If the problem persists, please contact support.'
        }
    }
}
</script>
