<template>
    <BigHeaderComponent :imageSrc="header.imageSrc" :imageAlt="header.imageAlt" />
    <DiscoverComponent :title="discover.title" :description="discover.description" :imageSrc="discover.imageSrc"
        :imageAlt="discover.imageAlt" />
    <CtaComponent :title="cta.title" :description="cta.description" :button1Text="cta.button1Text"
        :button2Text="cta.button2Text" :logoText="cta.logoText" />
    <TripleSectionLayout />
    <TeamComponent />
    <TestimonialComponent />
    <FeatureShowcaseComponent :sectionTitle="featureShowcase.sectionTitle" :sectionDescription="featureShowcase.sectionDescription" :features="featureShowcase.features" />
    <LatestBlogsComponent />
    <CtaWithSignUpComponent />
    <ImprintComponent />
    <ContactComponent />
</template>

<script>
import BigHeaderComponent from '@/components/builder/BigHeaderComponent.vue'
import DiscoverComponent from '@/components/builder/DiscoverComponent.vue'
import CtaComponent from '@/components/builder/CtaComponent.vue'
import TripleSectionLayout from '@/components/builder/TripleSectionLayout.vue'
import TeamComponent from '@/components/builder/TeamComponent.vue'
import TestimonialComponent from '@/components/builder/TestimonialComponent.vue'
import FeatureShowcaseComponent from '@/components/builder/FeatureShowcaseComponent.vue'
import LatestBlogsComponent from '@/components/builder/LatestBlogsComponent.vue'
import CtaWithSignUpComponent from '@/components/builder/CtaWithSignUpComponent.vue'
import ImprintComponent from '@/components/builder/ImprintComponent.vue'
import ContactComponent from '@/components/builder/ContactComponent.vue'

export default {
    name: 'BuilderPage',
    components: {
        BigHeaderComponent,
        DiscoverComponent,
        CtaComponent,
        TripleSectionLayout,
        TeamComponent,
        TestimonialComponent,
        FeatureShowcaseComponent,
        LatestBlogsComponent,
        CtaWithSignUpComponent,
        ImprintComponent,
        ContactComponent,
    },
    data() {
        return {
            header: {
                imageSrc: require('@/assets/images/m6static_Cover_004.jpg'),
                imageAlt: 'm6static Cover',
            },
            discover: {
                title: 'Discover the World of Digital Art and Unleash Your Creativity',
                description: `At 6static, we offer a wide range of unique digital art products and services that cater to all your creative needs. Whether you're an artist looking to showcase your work or a buyer searching for one-of-a-kind pieces, our platform is designed to connect you with the best talent and artwork in the industry. Join our community today and experience the endless possibilities of digital art.`,
                imageSrc: require('@/assets/images/m6static_Preview_Tiles_001.jpg'),
                imageAlt: 'm6static Preview Tiles 001',
            },
            cta: {
                title: 'Unlock the Power of Discord Bots',
                description: 'Enhance your Discord server with our custom-built bots...',
                logoText: 'Innovative',
                button1Text: 'Learn More',
                button2Text: 'Sign Up',
            },
            featureShowcase: {
                sectionTitle: 'Discover the World of Digital Art and Unleash Your Creativity',
                sectionDescription: `At 6static, we offer a wide range of unique digital art products and services that cater to all your creative needs. Whether you're an artist looking to showcase your work or a buyer searching for one-of-a-kind pieces, our platform is designed to connect you with the best talent and artwork in the industry. Join our community today and experience the endless possibilities of digital art.`,
                features: [
                    {
                        title: 'Efficient Coding',
                        description: 'Our team of experienced developers ensures that your Discord bot is built with efficient coding practices, resulting in smooth performance and minimal downtime.',
                    },
                    {
                        title: 'Customizable Bots',
                        description: `Tailor your Discord bot to suit your server's unique needs and preferences. From custom commands to personalized features, we've got you covered.`,
                    },
                    {
                        title: 'Reliable Support',
                        description: `We're here to support you every step of the way. Our dedicated team is available to assist with any questions or issues you may encounter.`
                    }
                ]
            },
        }
    },
}
</script>