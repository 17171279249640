// src/router.js
import {
    createRouter,
    createWebHistory
} from 'vue-router'

// Import all your page components
import HomePage from '@/views/HomePage.vue'
import ShopPage from '@/views/ShopPage.vue'
import BuilderPage from '@/views/BuilderPage.vue'
import ImprintPage from '@/views/ImprintPage.vue'
import TermsPage from '@/views/TermsPage.vue'
import AboutUsPage from '@/views/AboutUsPage.vue'
import ContactPage from '@/views/ContactPage.vue'
import BotServicesPage from '@/views/BotServicesPage.vue'
import NotFoundPage from '@/views/NotFoundPage.vue'
import BlogPage from '@/views/BlogPage.vue'
import BlogPostPage from '@/views/BlogPostPage.vue'
import NewsPage from '@/views/NewsPage.vue'
import NewsDetailPage from '@/views/NewsDetailPage.vue'
import DigitalArtPage from '@/views/DigitalArtPage.vue'
import DiscordBotsPage from '@/views/DiscordBotsPage.vue'
import DiscordBotsDetailPage from '@/views/DiscordBotsDetailPage.vue'
import PrivacyPolicyPage from '@/views/PrivacyPolicyPage.vue'
import PortfolioPage from '@/views/PortfolioPage.vue'

const routes = [{
        path: '/',
        name: 'Home',
        component: HomePage,
    }, {
        alias: ['/home', '/start', '/index'],
        redirect: {
            name: 'Home'
        }
    }, {
        path: '/blog',
        name: 'Blog',
        component: BlogPage,
    }, {
        alias: ['/blogs'],
        redirect: {
            name: 'Blog'
        }
    }, {
        path: '/blog/:slug',
        name: 'BlogPost',
        component: BlogPostPage,
        props: true
    }, {
        path: '/news',
        name: 'News',
        component: NewsPage
    }, {
        path: '/news/:slug',
        name: 'NewsDetail',
        component: NewsDetailPage,
        props: true
    }, {
        path: '/shop',
        name: 'Shop',
        component: ShopPage
    }, {
        path: '/shop/digital-art',
        name: 'DigitalArt',
        component: DigitalArtPage
    }, {
        path: '/shop/discord-bots',
        name: 'DiscordBots',
        component: DiscordBotsPage
    }, {
        alias: ['/shop/bots', '/shop/bot-services'],
        redirect: {
            name: 'DiscordBots'
        }
    }, {
        path: '/shop/discord-bots/:slug',
        name: 'DiscordBotDetail',
        component: DiscordBotsDetailPage,
    }, {
        path: '/legal/imprint',
        name: 'Imprint',
        component: ImprintPage
    }, {
        alias: ['/legal/impressum', '/imprint', '/impressum'],
        redirect: {
            name: 'Imprint'
        }
    }, {
        path: '/legal/terms-and-conditions',
        name: 'TermsAndConditions',
        component: TermsPage
    }, {
        alias: ['/terms-and-conditions', '/terms', '/terms-of-service'],
        redirect: {
            name: 'TermsAndConditions'
        }
    }, {
        path: '/legal/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicyPage
    }, {
        alias: ['/privacy-policy', '/privacy'],
        redirect: {
            name: 'PrivacyPolicy'
        }
    }, {
        path: '/about',
        name: 'AboutUs',
        component: AboutUsPage
    }, {
        alias: ['/about-us', '/aboutus'],
        redirect: {
            name: 'AboutUs'
        }
    }, {
        path: '/contact',
        name: 'Contact',
        component: ContactPage
    }, {
        path: '/bot-services',
        name: 'BotServices',
        component: BotServicesPage
    }, {
        path: '/builder',
        name: 'Builder',
        component: BuilderPage
    }, {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFoundPage
    }, {
        alias: ['/404', '/error'],
        redirect: {
            name: 'NotFound'
        }
    }, {
        alias: ['/discord', '/discord-bot', '/discord-bots'],
        redirect: {
            name: 'DiscordBots'
        }
    }, {
        alias: ['/discord-bot/:slug', '/discord-bots/:slug'],
        redirect: {
            name: 'DiscordBotDetail'
        }
    }, {
        alias: ['/news/:slug', '/news-detail/:slug'],
        redirect: {
            name: 'NewsDetail'
        }
    }, {
        alias: ['/blog/:slug', '/blog-post/:slug'],
        redirect: {
            name: 'BlogPost'
        }
    }, {
        path: '/portfolio',
        name: 'Portfolio',
        component: PortfolioPage
    }
]


const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router