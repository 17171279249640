<template>
    <section class="main-container">
        <ContactComponent />
    </section>
</template>
<script>
import ContactComponent from '@/components/builder/ContactComponent.vue'

export default {
    name: 'ContactPage',
    components: {
        ContactComponent
    }
}
</script>
<style scoped>
.main-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
