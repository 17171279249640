<template>
    <ImprintComponent />
    <LegalComponent />
</template>

<script>
import ImprintComponent from '@/components/builder/ImprintComponent.vue'
import LegalComponent from '@/components/builder/LegalComponent.vue'

export default {
    name: 'ImprintPage',
    components: {
        ImprintComponent,
        LegalComponent,
    },
}
</script>