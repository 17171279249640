<template>
    <FeatureShowcaseComponent
        :features="discord_bot.features"
        :sectionTitle="discord_bot.sectionTitle"
        :sectionDescription="discord_bot.sectionDescription"
        :noMaxWidth="true"
        :imageWide="true"
    />
    <FeatureShowcaseComponent
        :sectionTitle="featureShowcase.sectionTitle"
        :sectionDescription="featureShowcase.sectionDescription"
        :features="featureShowcase.features"
    />
</template>
<script>
import FeatureShowcaseComponent from '@/components/builder/FeatureShowcaseComponent.vue'
export default {
    name: 'BotServicesPage',
    components: {
        FeatureShowcaseComponent
    },
    data() {
        return {
            featureShowcase: {
                sectionTitle: 'Discover Our Bots',
                sectionDescription: `Explore our range of Discord bots designed to enhance your server experience. Whether you need moderation tools, music playback, or fun games, we have the perfect bot for you!`,
                features: [
                    {
                        title: 'Efficient Coding',
                        description:
                            'Our bots are built with efficiency in mind, ensuring smooth performance and minimal response time.',
                    },
                    {
                        title: 'Customizable Bots',
                        description: `Tailor your Discord bot to suit your server's unique needs and preferences. From custom commands to personalized features, we've got you covered.`
                    },
                    {
                        title: 'Reliable Support',
                        description: `Our team is here to provide ongoing support and updates for your bots, ensuring they remain functional and up-to-date.`
                    }
                ]
            },
            discord_bot: {
                sectionTitle: 'Discord Bots',
                sectionDescription:
                    'Discord bots are a great way to add some fun and functionality to your server. They can be used for moderation, music, games, and more! Here are some of the bots we offer:',
                features: [
                    {
                        title: 'Music Bot',
                        description: 'BambiMusic',
                        imageSrc: require('@/assets/images/bambimusic_cover.jpg'),
                        imageAlt: 'BambiMusic Cover Picture'
                    },
                    {
                        title: 'Utility Bot',
                        description: 'Miepus',
                        imageSrc: require('@/assets/images/miepus_cover.jpg'),
                        imageAlt: 'Miepus Cover Picture'
                    },
                    {
                        title: 'Leveling Bot',
                        description: 'Nautikin Adventures',
                        imageSrc: require('@/assets/images/nautikin_adventures_cover.jpg'),
                        imageAlt: 'Nautiking Adventures Bot Cover Picture'
                    }
                ]
            }
        }
    }
}
</script>
