<template>
    <ButtonComponent :label="label" :isPrimary="isPrimary" :borderVisible="borderVisible" @click="$emit('click')" />
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent.vue';

export default {
    components: {
        ButtonComponent,
    },
    props: {
        label: {
            type: String,
            default: 'Learn More',
        },
        isPrimary: {
            type: Boolean,
            default: true,
        },
        borderVisible: {
            type: Boolean,
            default: true,
        },
    },
}
</script>
