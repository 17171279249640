<template>
    <section class="main-container is-column">
        <MediumHeaderComponent
            :title="'Privacy Policy'"
            :description="'This is the privacy policy of our application. We value your privacy and are committed to protecting your personal data.'"
            :showImage="false"
            :showButtons="false"
        />
        <section>
            <h2>General use of website</h2>
            <p>
                We don't capture personal data, but your internet browser send
                automatically the following informations to internet service
                providers, wich stored there in a log file.
            </p>
            <ol>
                <li>IP address of the requesting computer</li>
                <li>
                    Used internet browser, operating system and internet service
                    provider.
                </li>
                <li>Date and time of access</li>
                <li>Previously visited website (Referrer URL)</li>
                <li>Name and URL of the accessed file</li>
            </ol>
        </section>
        <section>
            <h2>Contact</h2>
            <p>
                If you contact us by email, order or contact form, we only store
                your sent personal data in that extend (name, address, message),
                wich is necessary to contact you and for follow-up questions. By
                submitting your message you agree to the processing of your
                transmitted data. You can withdraw your consent at any time by
                contacting us.
            </p>
        </section>
        <section>
            <h2>Duration of storage</h2>
            <p>
                Personal data will be deleted after the legal retention period
                has expired, unless you have consented to further processing and
                use.
            </p>
        </section>
        <section>
            <h2>Rights of the affected person</h2>
            <p>
                If the legal requirements are fulfilled, you have the following
                rights according to art. 15 to 21 GDPR: Right to information,
                correction, deletion, restriction of processing, data
                portability. You also have the right of objection against
                processing, based on art. 6 (1) GDPR, and to processing for the
                purposes of direct marketing,
            </p>
        </section>
        <section>
            <h2>
                Contact us for questions. Our contact details can be found in
                our imprint.
            </h2>
        </section>
        <hr />
        <div>Date: {{ lastEdited }}</div>
    </section>
</template>
<script>
import MediumHeaderComponent from '@/components/builder/MediumHeaderComponent.vue'

export default {
    name: 'PrivacyPolicyPage',
    components: {
        MediumHeaderComponent
    },
    data() {
        return {
            lastEdited: '2024/09/24'
        }
    }
}
</script>
<style scoped>
li {
    list-style-type: decimal;
}
</style>
